import styles from "../Main.module.css";
import { useTranslation } from "next-i18next";
import Typing from "/components/typing";
import { useContext, useEffect, useRef, useState } from "react";
import RoomCard from "../../layout/SearchHeader/RoomCard";
import styled from "styled-components";
import { useRouter } from "next/router";
import FilterBox from "../../layout/SearchHeader/FilterBox";
import { getSearchMeta, getWebMain } from "@modules/api/Search";
import DatePickerBox from "@components/layout/SearchHeader/DatePickerBox";
import { Contains } from "@constants/contains";
import SearchHeader from "../../layout/SearchHeader";
import { getRoomQuery, getSearchDateCookie, getSearchRoomsCookie, setSearchDateCookie, setSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
import { disableBodyScrollLock, enableBodyScrollLock } from "@modules/utils/BodyScrollLock";
import SearchBtn from "@components/Button/SearchBtn";
import { useMediaQuery } from "react-responsive";
import ScrollupSearchBox from "@components/main/SearchBox/ScrollupSearchBox";
import { useDomain } from "@modules/hooks/useDomain";
import { getValueByDomain } from "@modules/utils/getValuewithDomain";
import IcSearchClear from "@public/images/common/ic_search_clear.svg";
import { useDebounce } from "@modules/hooks/useDebounce";
import { SEARCH_INPUT_DELAY } from "@constants/common";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { fPixelTrack } from "@modules/lib/fpixel/track";
import { FACEBOOK_PIXEL_TRACKING_TYPE } from "@constants/scripts";
import { useAtom } from "jotai";
import { webMainAtom } from "@store/global";
import { MetaSourceContext } from "@components/meta/MetaSourceProvider";
dayjs.locale("ko");
export default function SearchBox({
  setOpenSearchBar
}) {
  // 다국어
  const {
    t
  } = useTranslation("common");
  const {
    locale
  } = useRouter();
  const router = useRouter();
  const isDesktop = useMediaQuery({
    minDeviceWidth: 1025
  });
  const isTTBB = useDomain();
  const [searchData, setSearchData] = useState({
    searchId: "",
    searchType: "",
    query: "",
    startDate: null,
    endDate: null,
    roomsInfo: null,
    showDaysLayer: false,
    searchText: ""
  });
  // dim
  const [viewState, setViewState] = useState({
    dim: false
  });
  const [webMain, setWebMain] = useAtom(webMainAtom);
  const [isLoaded, setIsLoaded] = useState(false);
  const [innerWidth, setInnerWidth] = useState(false);
  // 검색박스 UI 동작
  const [isSearchScrollY, setIsSearchScrollY] = useState(false);
  const [isSearchClick, setIsSearchClick] = useState({
    state: false,
    clickItem: null
  });
  const [isSearchTopFixed, setIsSearchTopFixed] = useState(false);
  const searchBoxRef = useRef();
  const searchSearchBoxRef = useRef();
  const roomsBoxRef = useRef();
  // 검색어
  const [isSearchBoxFocus, setIsSearchBoxFocus] = useState(false);
  const [searchMetaData, setSearchMetaData] = useState(null);
  const [searchBoxTextFlag, setSearchBoxTextFlag] = useState(true);
  const searchTextRef = useRef();
  // 숙박일
  const [isDateBoxFocus, setIsDateBoxFocus] = useState(false);
  // 인원
  const [isRoomBoxFocus, setIsRoomBoxFocus] = useState(false);
  const [selectRoomCnt, setSelectRoomCnt] = useState(1);
  const [selectPeopleCnt, setSelectPeopleCnt] = useState(2);
  const txtInput = useDebounce(searchData.query, SEARCH_INPUT_DELAY);
  const timeStamp = useRef("");
  const contextMetaSource = useContext(MetaSourceContext);
  useEffect(() => {
    if (!router.isReady) return;
    setIsLoaded(true);
  }, [router.isReady]);

  // 데이터 호출
  useEffect(() => {
    getWebMain(locale, contextMetaSource.meta.name).then(data => setWebMain(data.body));
  }, [locale, contextMetaSource]);
  useEffect(() => {
    const roomsInfo = getSearchRoomsCookie();
    setSearchData({
      ...searchData,
      ...getSearchDateCookie(),
      roomsInfo
    });
    roomSelectCompleteHandle(roomsInfo);
  }, []);

  /**/
  useEffect(() => {
    const {
      innerWidth
    } = window;
    setInnerWidth(innerWidth);
    setTimeout(() => {
      if (searchBoxRef.current) searchBoxRef.current.style.display = "flex";
    }, 500);
  }, []);
  useEffect(() => {
    if (!searchBoxTextFlag) {
      setSearchData({
        ...searchData,
        query: "",
        searchText: ""
      });
    }
  }, [searchBoxTextFlag]);
  useEffect(() => {
    if (typeof window !== undefined) {
      const resetTxtInput = e => {
        if (isSearchBoxFocus && searchData.query && e.keyCode === 27) {
          setSearchBoxTextFlag(false);
          searchTextRef.current?.focus();
        }
      };
      window.addEventListener("keydown", resetTxtInput);
      return () => window.removeEventListener("keydown", resetTxtInput);
    }
  }, [isSearchBoxFocus, searchData.query]);
  const onScroll = () => {
    const {
      scrollY,
      innerWidth
    } = window;
    if (isDesktop) {
      setIsSearchScrollY(scrollY > 400);
    } else {
      setIsSearchScrollY(false);
    }
    setInnerWidth(innerWidth);
    searchTextRef.current?.blur();

    /**
     * 스크롤시 메인 SearchBox 닫히지 않도록 처리, 상단 fix시에만 동작하도록 아래 useEffect에서 처리
     */
    // setIsSearchBoxFocus(false);
    // setIsDateBoxFocus(false);
    // setIsRoomBoxFocus(false);
    roomSelectCompleteHandle(searchData.roomsInfo);
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll, {
      passive: true
    });
    window.addEventListener("resize", onScroll, {
      passive: true
    });
    return () => {
      window.removeEventListener("scroll", onScroll, {
        passive: true
      });
      window.removeEventListener("resize", onScroll, {
        passive: true
      });
    };
  }, [searchData.roomsInfo]);
  useEffect(() => {
    setIsSearchTopFixed(isSearchScrollY && isSearchClick.state);
    if (isSearchScrollY) {
      setIsSearchBoxFocus(false);
      setIsDateBoxFocus(false);
      setIsRoomBoxFocus(false);
    }
  }, [isSearchScrollY, isSearchClick.state]);
  useEffect(() => {
    if (isSearchTopFixed) {
      setViewState({
        ...viewState,
        dim: true
      });
      enableBodyScrollLock();
    } else {
      setViewState({
        ...viewState,
        dim: false
      });
      disableBodyScrollLock();
    }
  }, [isSearchTopFixed]);
  useEffect(() => {
    getMetaData();
  }, [locale, txtInput]);
  const getMetaData = async () => {
    try {
      const dataTimestamp = +new Date();
      let data;
      if (txtInput) {
        data = await getSearchMeta(locale, {
          query: txtInput,
          offset: 0,
          limit: 10,
          indices: [{
            name: "meta_region"
          }, {
            name: "meta_hotel"
          }]
        });
      }
      if (dataTimestamp > timeStamp.current) {
        if (txtInput) {
          setSearchMetaData(data.body);
        } else {
          setSearchMetaData(null);
        }
        timeStamp.current = dataTimestamp;
      }
    } catch (e) {
      console.log("metaDataAPIError :", e);
    }
  };
  const dimClickHandler = () => {
    setIsSearchClick({
      state: false,
      clickItem: null
    });
    setOpenSearchBar(false);
    setIsSearchBoxFocus(false);
    setIsRoomBoxFocus(false);
  };
  const searchTextHandle = e => {
    setSearchBoxTextFlag(true);
    setSearchData({
      ...searchData,
      query: e.target.value,
      searchText: e.target.value
    });
  };
  const handleCalendarClose = () => setIsDateBoxFocus(false);
  const roomSelectCompleteHandle = (roomsInfo, isClose) => {
    if (!roomsInfo) return;
    setSelectRoomCnt(roomsInfo?.length ?? 0);
    let peopleCnt = 0;
    roomsInfo?.map(info => {
      peopleCnt = peopleCnt + info.people.adultCnt + info.people.childs.length;
    });
    setSelectPeopleCnt(peopleCnt);
    if (isClose === true) {
      setSearchData({
        ...searchData,
        roomsInfo: roomsInfo
      });
      setIsRoomBoxFocus(false);
    }
  };

  // 검색 버튼
  const searchBtnHandler = () => {
    const {
      innerWidth
    } = window;
    if (innerWidth <= 1024) {
      router.push({
        pathname: "/search/mobile"
      });
      return;
    }
    if (!searchData.query || !searchData.searchId) {
      searchTextRef.current?.focus();
      return;
    }
    setIsSearchBoxFocus(false);
    setIsDateBoxFocus(false);
    setIsRoomBoxFocus(false);
    setSearchDateCookie(searchData.startDate, searchData.endDate);
    setSearchRoomsCookie(searchData.roomsInfo);
    const query = {
      "check-in": dayjs(searchData.startDate).format("YYYY.MM.DD"),
      "check-out": dayjs(searchData.endDate).format("YYYY.MM.DD"),
      ...getRoomQuery(searchData.roomsInfo),
      query: searchData.query,
      searchId: searchData.searchId,
      searchType: searchData.searchType,
      searchText: searchData.searchText
    };
    if (!isTTBB && process.env.NEXT_PUBLIC_DEV_ENV !== "development") {
      fPixelTrack(FACEBOOK_PIXEL_TRACKING_TYPE.SEARCH, query);
    }
    if (searchData.searchType === Contains.HOTEL) {
      router.push({
        pathname: "/hotels/[id]",
        query: {
          id: searchData.searchId,
          ...query
        }
      }, undefined);
    } else {
      router.push({
        pathname: "/search",
        query: query
      });
    }
  };
  return <>
      {isSearchScrollY && isSearchClick.state && <SearchHeader className={"!z-[12]"} searchData={searchData} setSearchData={setSearchData} isSearchClick={isSearchClick} isMainDim={viewState.dim} />}
      <div onClick={dimClickHandler} className={`${viewState.dim ? "block" : "hidden"} fixed bottom-0 left-0 z-[11] h-[calc(100%-209px)] w-full bg-DimBlack-7`} />
      {webMain && <section id="mGroup1" className={`${styles.mGroup1} relative h-[715px] p-[194px_0_289px] tablet:h-[428px] tablet:!bg-none
            tablet:pb-[150px] tablet:pt-[130px] mobile:h-[280px]
            mobile:p-[60px_0]`} style={webMain?.background_image_url ? {
      background: "url('" + webMain?.background_image_url + "') no-repeat center 0"
    } : {}}>
          <h2 className="D1_80_100_Bold tablet:D5_48_100_Bold mobile:H2_28_145_Bold relative z-[2] mt-[20px] min-h-[120px] animate-cursor
                   text-center text-Gray-900 tablet:mt-0
                   tablet:min-h-[72px] mobile:h-[82px] mobile:min-h-[41px] mobile:px-[16px]">
            {webMain?.title && <Typing Tag="span" preDelay={600} postDelay={600} loop={true}>
                {webMain?.title}
              </Typing>}
          </h2>

          {/*scrollup 클릭 전 search box*/}
          {isSearchScrollY && !isSearchClick.state && <ScrollupSearchBox setIsSearchClick={setIsSearchClick} searchData={searchData} setSearchData={setSearchData} setOpenSearchBar={setOpenSearchBar} selectPeopleCnt={selectPeopleCnt} />}

          {/*main searchbox*/}
          <div className={`mt-[20px] w-full ${isSearchScrollY && "hidden"}`}>
            <div ref={searchBoxRef} className={`search relative z-[3] mx-auto flex h-[72px] w-[980px] flex-row items-center rounded-[100px]
                      bg-white shadow-main
                      tablet:h-[56px] tablet:w-[604px]
                      mobile:m-[30px_24px_0] mobile:h-[48px] mobile:w-[auto] ${isTTBB && "tablet:border tablet:border-Gray-200"}
                      hidden`}>
              <BoxDiv ref={searchSearchBoxRef} className="w-[376px] tablet:w-[532px] tablet:after:hidden mobile:w-[432px]" isAfter isFocus={isSearchBoxFocus}>
                <input id="schInput" className={`schInput bg-transparent B_16_100_Medium placeholder:B_16_100_Medium tablet:B_16_100_Regular tablet:placeholder:B_16_100_Regular mobile:top-0.3 placeholder:bg-transparent relative left-[32px] top-[1px] z-[2]
                            inline-block w-[312px] cursor-pointer overflow-ellipsis border-none p-0
                            text-Gray-800 caret-Primary500 
                            placeholder:text-Gray-400 focus:placeholder:text-Gray-100 tablet:top-0.5
                            tablet:h-[49px] tablet:w-[500px] mobile:h-[42px] mobile:w-[calc(100%-90px)]
                            ${isSearchBoxFocus ? "top-[36px] h-[auto] leading-[normal]" : "h-[calc(100%_-_2px)]"}`} type="text" ref={searchTextRef} placeholder={t("searchbar.search.placeholder")} value={searchData.searchText || ""} onChange={searchTextHandle} readOnly={innerWidth <= 1024} maxLength={50} onFocus={() => {
              setIsSearchBoxFocus(true);
              setIsRoomBoxFocus(false);
            }} onClick={() => {
              if (innerWidth <= 1024) {
                router.push("/search/mobile");
              }
            }} />
                {isSearchBoxFocus && searchData.query && <IcSearchClear width="18px" height="18px" className="absolute right-[30px] top-[36px] z-[10] cursor-pointer" onClick={() => {
              // setSearchData({
              //   ...searchData,
              //   query: "",
              //   searchText: "",
              // });
              setSearchBoxTextFlag(false);
              searchTextRef?.current?.focus();
            }} />}
                <label className={`C_12_100_Medium absolute left-[32px] ${getValueByDomain("text-Gray-300", "text-Gray-500")} transition-all duration-[.3s] ease-[ease] tablet:hidden ${isSearchBoxFocus ? "top-[16px] opacity-100" : "top-[14px] opacity-0"}`}>
                  {t("searchbar.search.label")}
                </label>
                {isSearchBoxFocus && <FilterBox className={"tablet:hidden"} searchSearchBoxRef={searchSearchBoxRef} searchTextRef={searchTextRef} searchData={searchData} setSearchData={setSearchData} searchBoxCloseHandler={() => {
              setIsSearchBoxFocus(false);
              setSearchBoxTextFlag(true);
            }} searchKeywords={webMain.search_keywords} searchMetaData={searchData.query && searchMetaData} />}
              </BoxDiv>
              <BoxDiv className="tablet:hidden" isAfter isFocus={isDateBoxFocus}>
                {isLoaded && <DatePickerBox closeOnScroll={isSearchScrollY} className={`B_16_100_Medium tablet:B_16_100_Regular flex h-[72px]
                  w-[260px] items-center rounded-[100px] border-none pl-[32px] text-Gray-800
                  tablet:h-[56px] tablet:w-[500px] tablet:focus:h-[56px] tablet:focus:pt-0
                  mobile:h-[48px] mobile:w-[calc(100%-16px)] mobile:pl-[24px] mobile:focus:h-[48px]
                  ${isDateBoxFocus ? "leading-[normal pt-[18px] " : ""}`} dateFormat={t("search.datepicker.format")} searchData={searchData} setSearchData={setSearchData} openHandler={() => {
              setIsDateBoxFocus(true);
              setIsSearchBoxFocus(false);
              setIsRoomBoxFocus(false);
            }} closeHandler={handleCalendarClose} />}
                <label className={`C_12_100_Medium absolute left-[32px] ${getValueByDomain("text-Gray-300", "text-Gray-500")} transition-all duration-[.3s] ease-[ease] tablet:hidden ${isDateBoxFocus ? "top-[16px] opacity-100" : "top-[14px] opacity-0"}`}>
                  {t("searchbar.days.label")}
                </label>
              </BoxDiv>
              <BoxDiv ref={roomsBoxRef} className="tablet:hidden" isFocus={isRoomBoxFocus}>
                <div className={`num B_16_100_Medium tablet:B_16_100_Regular relative z-[5] flex h-[72px]
                        w-[195px] cursor-pointer items-center rounded-[100px] border-none pl-[32px] text-Gray-800
                        tablet:h-[56px] tablet:w-[500px] tablet:focus:h-[56px] tablet:focus:pt-0
                        mobile:h-[48px] mobile:w-[calc(100%-16px)] mobile:pl-[24px] mobile:focus:h-[48px]
                        ${isRoomBoxFocus ? "pt-[18px] leading-[normal]" : ""}`} onClick={() => {
              setIsRoomBoxFocus(!isRoomBoxFocus);
              setIsSearchBoxFocus(false);
            }}>
                  {t("searchbar.people.txt", {
                n: {
                  a: selectRoomCnt,
                  b: selectPeopleCnt
                }
              })}
                </div>
                <label className={`C_12_100_Medium absolute left-[32px] ${getValueByDomain("text-Gray-300", "text-Gray-500")} transition-all duration-[.3s] ease-[ease] tablet:hidden ${isRoomBoxFocus ? "top-[16px] opacity-100" : "top-[14px] opacity-0"}`}>
                  {t("searchbar.people.label")}
                </label>
                {isRoomBoxFocus && <RoomCard roomsBoxRef={roomsBoxRef} isRoomBoxFocus={isRoomBoxFocus} setIsRoomBoxFocus={setIsRoomBoxFocus} defaultRoomInfo={searchData?.roomsInfo} roomBoxCloseHandler={() => {
              setIsRoomBoxFocus(false);
            }} roomSelectCompleteHandle={roomSelectCompleteHandle} />}
              </BoxDiv>
              <SearchBtn onClick={searchBtnHandler} text={t("searchbar.btn.search")} type="main_search" />
            </div>
          </div>
        </section>}
    </>;
}
const BoxDiv = styled.div.withConfig({
  displayName: "SearchBox__BoxDiv",
  componentId: "sc-1jd7dxb-0"
})(["", " ", " ", ""], {
  "position": "relative",
  "height": "72px",
  "@media (max-width: 1024px)": {
    "height": "56px"
  },
  "@media (max-width: 767px)": {
    "height": "48px"
  }
}, props => props.isAfter ? {
  "::after": {
    "content": "var(--tw-content)",
    "position": "absolute",
    "right": "0px",
    "top": "24px",
    "height": "24px",
    "width": "1px",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(247 247 250 / var(--tw-bg-opacity))",
    "--tw-content": "''"
  }
} : "", props => props.isFocus ? {
  "::before": {
    "content": "var(--tw-content)",
    "position": "absolute",
    "left": "0px",
    "top": "0px",
    "zIndex": "2",
    "height": "72px",
    "width": "100%",
    "borderRadius": "100px",
    "borderWidth": "1px",
    "borderStyle": "solid",
    "--tw-border-opacity": "1",
    "borderColor": "rgb(38 40 44 / var(--tw-border-opacity))",
    "--tw-content": "''"
  },
  "@media (max-width: 1024px)": {
    "::before": {
      "content": "var(--tw-content)",
      "display": "none"
    }
  }
} : "");